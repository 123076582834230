import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthContext from './store/auth-context';

// 동적으로 로드되는 컴포넌트들
const LaunchScreen = lazy(() => import('./components/Layout/LaunchScreen'));
const Login02 = lazy(() => import('./pages/Authentication/Login02'));
const LoginMng = lazy(() => import('./pages/Authentication/LoginMng'));
const Join = lazy(() => import('./pages/Authentication/Join'));
const UpdateUser = lazy(() => import('./components/User/UpdateUser'));
const FindPwd = lazy(() => import('./pages/Authentication/FindPwd'));
const FindId = lazy(() => import('./pages/Authentication/FindId'));
const PaymentPageSuccess = lazy(() => import('./pages/Payment/PaymentPageSuccess'));
const PaymentPageFail = lazy(() => import('./pages/Payment/PaymentPageFail'));
const UserDeleteAnnouncementPage = lazy(() => import('./pages/User/UserDeleteAnnouncementPage'));
const AllStatusPlatePage = lazy(() => import('./pages/StatusPlate/AllStatusPlatePage'));
const StatusPlatePage = lazy(() => import('./pages/StatusPlate/StatusPlatePage'));
const PrivateOutlet = lazy(() => import('./components/Layout/PrivateOutlet'));
const DashboardPage = lazy(() => import('./pages/Dashboard/DashboardPage'));
const RequestBoardDetail = lazy(() => import('./components/Bulletinboard/RequestBoardDetail'));
const RequestBoardAdd = lazy(() => import('./components/Bulletinboard/RequestBoardAdd'));
const NoticeBoardDetail = lazy(() => import('./components/Bulletinboard/NoticeBoardDetail'));
const InquiryBoardDetail = lazy(() => import('./components/Bulletinboard/InquiryBoardDetail'));
const InquiryBoardAdd = lazy(() => import('./components/Bulletinboard/InquiryBoardAdd'));
const ShopProductCardDetail = lazy(() => import('./components/Products/ShopProductCardDetail'));
const OrderInfoPage = lazy(() => import('./pages/Order/OrderInfoPage'));
const ConnectionPlantPage = lazy(() => import('./pages/Plant/ConnectionPlantPage'));
const RequestConnectionPlant = lazy(() =>
  import('./components/Plant/ConnectionPlantPageComponent/RequestConnectionPlant')
);
const AcceptConnectionPlant = lazy(() =>
  import('./components/Plant/ConnectionPlantPageComponent/AcceptConnectionPlant')
);
const NotFound = lazy(() => import('./components/Error/NotFound'));

// 메뉴 설정을 미리 가져오는 함수
async function preloadMenuConfigs() {
  const module = await import('./config/menu');
  return module.default;
}

// React 애플리케이션의 전반적인 구조를 구성하고 라우팅을 설정하며 인증을 관리하고 로딩 상태를 처리하는 중심 컴포넌트
export default function App() {
  const authCtx = useContext(AuthContext);
  const loggedInUserRole = authCtx?.currentUser?.role;

  const [loading, setLoading] = useState(true); // 초기 로딩 상태
  const [menuConfigs, setMenuConfigs] = useState(null); // 메뉴 설정 상태

  // 초기 로딩 처리 및 메뉴 설정 미리 로드
  useEffect(() => {
    // LaunchScreen을 1초 동안 표시
    setTimeout(() => setLoading(false), 1000);

    // 메뉴 설정 로드
    preloadMenuConfigs().then((configs) => {
      setMenuConfigs(configs);
    });
  }, []);

  // 로딩 중이거나 메뉴 설정이 없을 때 LaunchScreen 표시
  if (loading || !menuConfigs) {
    return <LaunchScreen />;
  }

  return (
    <Suspense fallback={<LaunchScreen />}>
      <Routes>
        <Route path="/login02" element={<Login02 />} />
        <Route path="/loginMng" element={<LoginMng />} />
        <Route path="/join" element={<Join />} />
        <Route path="/updateUser" element={<UpdateUser />} />
        <Route path="/findPwd" element={<FindPwd />} />
        <Route path="/findId" element={<FindId />} />
        <Route path="/paymentPageSuccess" element={<PaymentPageSuccess />} />
        <Route path="/paymentPageFail" element={<PaymentPageFail />} />
        <Route path="/deleteAnnouncement" element={<UserDeleteAnnouncementPage />} />
        <Route path="/allStatusPlate" element={<AllStatusPlatePage />} />
        <Route path="/statusPlatePage" element={<StatusPlatePage />} />
        <Route path="/" element={<PrivateOutlet />}>
          <Route path="" element={<DashboardPage />} />
          {menuConfigs.map((mc, index) => (
            <Route
              key={index}
              path={mc.path}
              element={mc.roles.includes(loggedInUserRole) ? mc.component : <Navigate to="/" />}
            />
          ))}
          <Route path="/bulletinboards/request/detail/:seq" element={<RequestBoardDetail />} />
          <Route path="/bulletinboards/request/add" element={<RequestBoardAdd />} />
          <Route path="/bulletinboards/notice/detail/:seq" element={<NoticeBoardDetail />} />
          <Route path="/bulletinboards/inquiry/detail/:seq" element={<InquiryBoardDetail />} />
          <Route path="/bulletinboards/inquiry/add" element={<InquiryBoardAdd />} />
          <Route path="/products/detail/:id" element={<ShopProductCardDetail />} />
          <Route path="/orders/info/:id" element={<OrderInfoPage />} />
          <Route path="/connectionplants/accept" element={<ConnectionPlantPage />} />
          <Route path="/connectionplants/request" element={<RequestConnectionPlant />} />
          <Route path="/connectionplants/accept/:radioValue/:ukey" element={<AcceptConnectionPlant />} />
        </Route>
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
