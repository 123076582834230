import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ko from 'dayjs/locale/ko';
import { AuthContextProvider } from './store/auth-context';
import ThemeProvider from './theme';
import UseScrollToTop from './hooks/useScrollToTop';
import { CookiesProvider } from 'react-cookie';

// React 애플리케이션의 초기화와 설정을 담당하며, 필요한 컨텍스트 및 라이브러리를 제공하여 애플리케이션을 구동하는 역할
// React 애플리케이션의 진입점(entry point)으로서 역할
const domain = process.env.REACT_APP_DOMAIN;

if (domain === 'SC') {
  document.title = 'SOLARCUBE 태양광 모니터링';
} else if (domain === 'DE') {
  document.title = 'SOLARCUBE II 태양광 모니터링';
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LocalizationProvider adapterLocale={ko} dateAdapter={AdapterDayjs}>
    <AuthContextProvider>
      <BrowserRouter>
        <UseScrollToTop />
        <CookiesProvider>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </CookiesProvider>
      </BrowserRouter>
    </AuthContextProvider>
  </LocalizationProvider>
);
