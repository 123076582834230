import { createContext, useCallback, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { getMilisecond, getMilisecondUtcNow } from '../utils/dateUtil';
import config from '../config';

// React 컨텍스트를 활용하여 사용자 인증과 관련된 상태 및 기능을 관리하는 컴포넌트
// 애플리케이션 전역에서 사용자 인증과 관련된 상태를 효과적으로 관리하기 위한 컴포넌트

let logoutTimer;

const AuthContext = createContext({
  token: null,
  isLoggedIn: false,
  currentUser: null,
  login: (token) => {},
  logout: () => {},
  isAndroid: null,
  isiOS: null,
});

const tokenExpireLimit = 60 * 1000; // milliseconds

const calculateRemainingTime = (expires) => {
  return getMilisecond(expires) - getMilisecondUtcNow();
};

const retrieveStoredToken = () => {
  return localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
};

const parseCurrentUser = (token) => {
  const jwt = jwtDecode(token.accessToken.token);
  return {
    key: +jwt.key,
    id: jwt.sub,
    name: jwt.name,
    role: jwt.auth,
    companyId: +jwt.companyId,
    ogLogoImgKey: jwt.logoImgKey,
  };
};

export const AuthContextProvider = ({ children }) => {
  const initialToken = retrieveStoredToken();
  const [token, setToken] = useState(initialToken);

  const isLoggedIn = !!token;

  // const isAndroid = navigator.userAgent.toLowerCase().indexOf('solarcubeii_android') !== -1;
  const isAndroid =
    navigator.userAgent.indexOf(
      process.env.REACT_APP_MOBILE_AGENT_PREFIX + process.env.REACT_APP_MOBILE_AGENT_MIDFIX_ANDROID
    ) !== -1;

  // const isiOS = navigator.userAgent.toLowerCase().indexOf('solarcubeii_ios') !== -1;
  const isiOS =
    navigator.userAgent.indexOf(
      process.env.REACT_APP_MOBILE_AGENT_PREFIX + process.env.REACT_APP_MOBILE_AGENT_MIDFIX_IOS
    ) !== -1;

  let currentUser;

  const setCurrentUser = useCallback(() => {
    if (token) {
      currentUser = parseCurrentUser(token);
      return currentUser;
    }
  }, [token]);

  setCurrentUser();

  const loginHandler = (token) => {
    setToken(token);
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('login', 'login');
    localStorage.setItem('menuValue', 0);
    localStorage.setItem('menuSubValue', 0);
  };

  const logoutHanlder = useCallback(() => {
    setToken(null);

    // const androidFunc = () => window.QWebApp.pushDeactive('logout');
    // const iOSFunc = () => window.webkit.messageHandlers.pushDeactive.postMessage('logout');

    // if (isAndroid) {
    //   androidFunc();
    // } else if (isiOS) {
    //   iOSFunc();
    // }

    localStorage.clear();

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const refreshToken = useCallback(
    (token) => async () => {
      try {
        const currentUser = parseCurrentUser(token);

        const res = await fetch(
          isAndroid || isiOS
            ? `${config.backend.hostMo}${config.backend.endPoint.authentication.refresh}`
            : `${config.backend.host}${config.backend.endPoint.authentication.refresh}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token.accessToken.token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: token.refreshToken,
              userId: currentUser.id,
            }),
          }
        );

        if (!res.ok) {
          throw new Error('Refresh Token Error');
        }

        const tokenFromRefresh = await res.json();
        loginHandler(tokenFromRefresh);
      } catch (error) {
        console.error(error);
        logoutHanlder();
        // alert('세션 아웃되었습니다.');
      }
    },
    [token]
  );

  useEffect(() => {
    if (token) {
      const remainingTime = calculateRemainingTime(token.accessToken.expires);

      if (remainingTime <= tokenExpireLimit) {
        refreshToken(token)();
      } else {
        if (logoutTimer) {
          clearTimeout(logoutTimer);
        }
        logoutTimer = setTimeout(refreshToken(token), remainingTime);

        setCurrentUser();
      }
    }
  }, [token]);

  const contextValue = {
    token: token,
    isLoggedIn,
    currentUser,
    login: loginHandler,
    logout: logoutHanlder,
    isAndroid,
    isiOS,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
