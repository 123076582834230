import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';

// dayjs 라이브러리를 사용하여 날짜와 시간과 같이 날짜와 관련된 여러 유틸리티 함수를 제공

dayjs.extend(dayjsUtc);

export const getMilisecond = (dateString) => {
  return dayjs.utc(dateString).valueOf();
};

export const getMilisecondUtcNow = () => {
  return dayjs.utc().valueOf();
};

export const formatTime = (date) => {
  return dayjs(date).format('HH:mm:ss');
};

export const convertFormatString = (date) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm');
};

export const convertFormatStringToYMD = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const convertFormatStringToYM = (date) => {
  return dayjs(date).format('YYYY-MM');
};

export const convertFormatStringToY = (date) => {
  return dayjs(date).format('YYYY');
};

export const convertStringToFormatDateString = (dateStr) => dayjs(dateStr).format('YYYY-MM-DD HH:mm:ss');

export const convertStringToFormatDateString02 = (dateStr) => dayjs(dateStr).format('YYYY.MM.DD HH:mm:ss');

export const convertFromatStringForFileName = () => {
  return dayjs().format('YYYYMMDDHHmmss');
};

export const getSearchDateFrom = () => dayjs().startOf('D');
export const getSearchDateTo = () => dayjs().endOf('D');
